@use './base';

.input-container {
  display: flex;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 20;
}

.dropdown-nav {
  order: 0;
  flex-grow: 15;
  width: 200px;
  max-width: 728px;
}

.topNavbar {
  padding-top: 15px;
  position: fixed;
  z-index: 100000;
  width: 100%;
  top: 0;
  left: 0;
}

.headerLink {
  text-decoration: none;
  color: white;
}

.headerDiv {
  color: white;
  font-size: 25px;
}

.toggleText {
  color: white;
  font-size: 15px;
}