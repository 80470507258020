.log_container {
  font-size: 12px;
  font-variant-ligatures: common-ligatures;
  background-color: #fafafa;
  border-radius: 3px;
  counter-reset: line;
}

.container_line_number:before {
  position: absolute;
  right: 100%;
  margin-right: 10px;
  text-align: right;
  opacity: .3;
  user-select: none;
  counter-increment: line;
  content: counter(line);
}

pre.log_container {
  white-space: pre-wrap;
}
pre.log_container::before {
  counter-reset: listing;
}
pre.log_container code {
  counter-increment: listing;
}
pre.log_container code {
  display: block;
  margin-left: 5em;
  padding-left: 2em;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  border-left: 1px solid #e9e6e6;
  margin-top: 0;
  margin-bottom: 0;
}
pre.log_container code::before {
  content: counter(listing);
  display: inline-block;
  width: 8em;  
  position: absolute;       
  left: 1em;
  text-align: right;  
}
/* pre.log_container code::before {
  content: counter(listing);
  display: inline-block;
  width: 8em;      
  padding-left: auto; 
  margin-left: auto;  
  text-align: right;  
} */

table.log_container {
  white-space: pre-wrap;
  width: inherit;
  word-break: break-word;
}

table.log_container td {
  width: 1px;
}

table.log_container td:last-child {
  width: 100%;
}

table.log_container td.index_number {
  border-right: 1px solid #e9e6e6;
  user-select: none;
  text-align: right;
  padding-left: 1em;
  padding-right: 0.75em;
  word-wrap:normal;
  white-space: nowrap;
}

table.log_container td.log_line {
  display: block;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
  word-wrap: break-word;
}

td.clicked_log_line {
  background-color: lightgreen
}

td.index_number {
  cursor: pointer;
}

td.log_line{
  font-family: "Menlo", "Lucida Console", "Bitstream Vera Sans Mono", monospace;
}

span.link{
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}